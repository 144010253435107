*:focus{
  outline: none;
}

.header-logo {
  height: 40px;
  padding-right: 10px;
}

#navbar-brand span {
  display: inline;
}
