.landing-page{

    .header{
        height: 100vh;
        position: relative;

        .container{
            padding-top: 40vh;
            color: #FFFFFF;
            z-index: 2;
            position: relative;
        }

        .title{
            color: $white-color;
        }
    }

}